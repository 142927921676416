<template>
<div class="flex column" style="overflow: hidden;">

    <div v-if="superSections.length && !selectedSuperSection" class="menu-items-container flex column" style="overflow: none; gap: 15px">
        <div class="btn btn-hover" style="overflow: none" @click.prevent="ClickSuperSection(section)" v-for="section in superSections" :key="`super-${section.title}`">
            <div class="flex column c-section-info">
                <!--<i class="icon icon-table c2" style="margin-bottom: 10px;"></i>-->
                <div class="s1 c1 c-section-title ta-center">{{ Util.TranslateLanguage(section.title)}}</div>
            </div>
        </div>
    </div>

    <div v-show="search && !menuResults.length" class="ta-center c2 c-no-results" style="padding-top: 20px">{{Util.TranslateLanguage('No se encontraron resultados')}}</div>
    <div v-show="menuResults.length && (!superSections.length || selectedSuperSection)" class="menu-items-container">

        <div  :class="`flex column section c-section c-section-${section.title.replace(/ /g, '')} ${section.unavailable ? 'section-unavailable' : ''}`" :id="`section${section.title.replace(/ /g, '')}`"
        v-for="section in menuResults" :key="`section-${section.title}`"
        v-show="section.items.length && (behaviour != 'TABS' || currentSection == section.title || search.trim())">

            <div :class="`c-section-card ${behaviour == 'EXPAND' ? 'btn btn-hover' : ''}`" @click="GoToSection(section)">
                <div v-show="false" class="c-section-image" :style="`background-image: url(${Util.img(section.image, 'products')})`"></div>
                <div class="flex column c-section-info">
                    <div class="ta-left s1 c1 c-section-title "  v-if="section.items.length > 0">{{ Util.TranslateLanguage(section.title) }}</div>
                    <div class="s3 c2 c-section-description ta-left" style="white-space: pre" v-if="section.description">{{Util.TranslateLanguage(section.description)}}</div>
                </div>
            </div>

            <div class="flex column items-container" v-show="behaviour != 'EXPAND' || currentSection == section.title || search.trim()" style="margin-top: 5px">
                <div v-for="(item, i) in section.items" :key="`item-${i}`" :ref="`item-${item.id}`" :class="`c-item-card ${item.unavailable ? 'item-unavailable' : ''}`">
                    <div :class="`btn fc ${canOpenItems ? 'btn-hover' : ''} c-item c-item-${item.title.replace(/ /g)} ${item.unavailable ? 'disabled' : ''}`" style="padding: 0px" 
                    @click="!item.unavailable && canOpenItems ? OpenModalItem(item) : $emit('OnClickItem', item)"
                    :title="canOpenItems ? (item.unavailable ? `${item.title} no está disponible` : `Agregar ${item.title} a mi pedido`) : Util.TranslateLanguage('Actualmente no se pueden hacer pedidos')">
                        <div class="flex fill c-item-container" style="border-radius: inherit; overflow: hidden">
                            <!--<img class="c-item-image" :src="Util.img(item.image, 'products')" :height="mobile ? 100 : 150" :width="mobile ? 100 : 150">-->
                            
                            <div class="c-item-image-container">
                                <div class="item-image c-item-image" :style="{'background-image': `url('${Util.img(item.image, 'products')}')`}"></div>
                            </div>
                            
                            <div class="flex column flex-fill fill c-item-info" style="padding: 15px">
                                <div class="flex column flex-fill">
                                    
                                    <vue-clamp :class="`s2 c1 ta-left c-item-title`" :max-lines="maxLines[`title${mobile ? 'Mobile' : 'Desktop'}`]" autoresize>{{Util.TranslateLanguage(item.title)}}</vue-clamp>
                                    <div class="s3 s4 c2 flex-fill ta-left c-item-description c-item-unavailable" style="padding-top: 5px" v-if="item.unavailable">{{Util.TranslateLanguage('No disponible')}}</div>
                                    <vue-clamp class="s3 s4 c2 flex-fill ta-left c-item-description" style="padding-top: 5px; line-height: 1;" v-else :max-lines="maxLines[`description${mobile ? 'Mobile' : 'Desktop'}`]" autoresize>{{Util.TranslateLanguage(item.description, null, true, item.id)}}</vue-clamp>
                                    
                                </div>
                                <div class="flex align-center c-item-bottom" style="margin-top: 3px; justify-content: right">
                                    <div class="s3 s4 c2 ta-left flex-fill c-item-reservation" style="line-height: 1;" :title="Util.TranslateLanguage('Horas de reserva anticipada')" v-if="item.anticipation"><i class="icon icon-clock" style="font-size: 10px; margin-left: 5px; margin-right: 5px;"></i> {{item.anticipation_time}} Hrs</div>
                                    <div class="s2 cn old-price c-item-old-price" style="margin-right: 5px; text-decoration: line-through" v-if="item.price_reduction && Util.ItemPrice(item, itemPriceDisplay)">{{Util.Price(item.price_old)}}</div> 
                                    <div class="s2 c1 ta-right c-item-price">{{Util.ItemPrice(item, itemPriceDisplay)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <modal v-model="modalItem" 
    :title="modalItem && !hideModalTitle ? Util.TranslateLanguage(modalItem.title) : null" 
    :desktopWidth="modalCombo?'60vw':null" 
    :desktopHeight="modalCombo?'75vh':null" 
    class="modal-item" 
    :closeable="!hideModalTitle">

        <div :class="`flex-fill flex ${mobile ? 'column' : 'ofy'}`" v-if="modalItem">
            <div v-show="!mobile && !modalCombo && itemImageSize == 'DEFAULT'" class="it-item-image it-item-image-default" :style="{'width': '40%', 'min-width': '40%', height: mobile ? '200px' : '100%', 'background-image': `url('${Util.img(modalItem.image, 'products')}')`, 'background-size': 'cover', 'background-position': 'center'}"></div>
            <div class="flex column fill">
                <div class="ofy flex-fill flex column relative it-item-container" style="padding: 20px; overflow-x: hidden">

                    <img v-show="mobile && !modalCombo && itemImageSize == 'DEFAULT'" :src="Util.img(modalItem.image, 'products')" class="it-item-image it-item-image-default" style="width: calc(100% + 40px); margin: -20px 0px 20px -20px">

                    <div v-if="Util.TranslateLanguage(modalItem.description, null, true, modalItem.id) || itemImageSize == 'SMALL'" class="it-item-info">
                        <div class="flex">
                            <img v-if="itemImageSize == 'SMALL'" :src="Util.img(modalItem.image, 'products')" class="logo it-item-image it-item-image-small" style="margin-right: 10px; width: 50px; height: 50px">
                            <div class="flex column">
                                <div v-if="hideModalTitle" class="c1 s1 it-item-title">{{ Util.TranslateLanguage(modalItem.title) }}</div>
                                
                                <div v-if="Util.TranslateLanguage(modalItem.description, null, true, modalItem.id)" class="c1 s3 it-item-description">{{Util.TranslateLanguage(modalItem.description, null, true, modalItem.id)}}</div>
                            </div>
                        </div>
                        <div class="separator"></div>
                    </div>
                    

                    <div :class="`flex column animated btn ${group.expand ? 'btn-hover' : ''} it-group`" style="margin-bottom: 10px; flex-shrink: 0; padding: 10px"
                    @click="group.expand ? modalModifiers = {group: group, index: indexGroup} : null"
                    v-for="(group, indexGroup) in modalItem.modifiers" :key="`group-${indexGroup}`" ref="modifierGroups">
                        
                        <div class="flex align-bottom">
                            <modifiers-panel :group="group" :idItem="modalItem.id" :hideModalTitle="hideModalTitle" class="flex-fill" ref="modsPanel"></modifiers-panel>
                            <i v-if="group.expand" class="icon icon-chevron-down c2" style="margin-right: 0px"></i>
                        </div>

                    </div>

                    <div v-show="canAddItems">
                        <div class="btn input label style flex-fill it-comment" :title="Util.TranslateLanguage('Editar cantidad')">
                            <div :class="`s1 label ${modalItem.comment_required ? (modalItem.comment ? 'cy' : 'cn') : ''}`" style="padding-left: 15px; text-align: left">{{modalItem.comment_instructions ? modalItem.comment_instructions : Util.TranslateLanguage('Comentario adicional')}}</div>
                            <input v-model="modalItem.comment" type="text" class="fill s1 w-label" :placeholder="modalItem.comment_required ? Util.TranslateLanguage('(Requerido)') : Util.TranslateLanguage('(Opcional)')" style="padding: 40px 15px 15px 15px;">
                        </div>
                    </div>

                    <div class="flex-fill"></div>
                </div>
                <div style="padding: 0px 20px 20px 20px;" v-show="canAddItems">
                    <div class="separator"></div>
                    <div :class="`flex ${hideModalTitle ? '' : 'column'}`" style="gap: 10px">
                        <div class="btn btn-hover flex justify-center align-center flex-fill" v-if="hideModalTitle" @click="modalItem = null">{{Util.TranslateLanguage('Volver')}}</div>
                        <div class="btn btn-hover flex justify-center align-center flex-fill" v-if="hideModalTitle && modalItem.edit_key" @click="$emit('OnRemove', {key: modalItem.edit_key}); modalItem = null; ">{{Util.TranslateLanguage('Quitar')}}</div>
                        <div class="flex column unshrink it-add-container" v-if="!modalCombo && (!modalItem.max_quantity_enabled || modalItem.max_quantity !== 1)">
                            <div v-if="!modalItem.float_quantity" class="flex" style="flex-shrink: 0; gap: 10px; height: 100%">
                                <div @click="modalItem.quantity > 1 ? modalItem.quantity-- : null" :class="`btn flex align-center btn-hover it-item-subtract ${modalItem.quantity <= 1 ? 'disabled' : ''}`" :title="`${Util.TranslateLanguage('Quitar')} 1`"><i class="icon icon-minus"></i></div>
                                <div class="btn input label style flex-fill" :title="Util.TranslateLanguage('Editar cantidad')" :style="hideModalTitle ? {width: '120px'} : ''">
                                    <div class="s3 label label-quantity">{{Util.TranslateLanguage('Cantidad')}}</div>
                                    <input v-model="modalItem.quantity" :readonly="hideModalTitle" type="text" class="fill s1 ta-center w-label input-quantity" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                                </div>
                                <div @click="modalItem.quantity++" class="btn flex align-center btn-hover it-item-add" :title="`${Util.TranslateLanguage('Agregar')} 1`"><i class="icon icon-plus"></i></div>
                            </div>
                            <div v-else class="flex it-float-quantity" style="flex-shrink: 0">
                                <div class="btn input style flex-fill" :title="`${Util.TranslateLanguage('Editar cantidad')}`" :style="hideModalTitle ? {width: '150px'} : ''">
                                    <div class="s3 label label-quantity">{{Util.TranslateLanguage('Cantidad')}}<span v-if="modalItem.float_quantity_measure"> ({{modalItem.float_quantity_measure}})</span></div>
                                    <input v-model="modalItem.quantity" type="text" class="fill s1 ta-center w-label input-quantity" oninput="this.value=this.value.replace(/[^0-9,]/g,'');">
                                </div>
                            </div>
                        </div>
                        <div v-if="!modalCombo" @click="currentItem.error ? ItemError(modalItem) : modalItem.anticipation? confirmAnticipation = {modalItem, currentItem, key: modalItem.edit_key} : AddItem(currentItem, modalItem.edit_key)" :class="`btn ${hideModalTitle ? 'invert' : ''} btn-hover flex justify-center align-center flex-fill it-add-to-combo ${currentItem.error ? 'disabled' : ''}`" :title="currentItem.error ? Util.TranslateLanguage('Rellena los campos requeridos') : Util.TranslateLanguage('Agregar a mi pedido')">{{modalItem.edit_key ? Util.TranslateLanguage('Editar') : Util.TranslateLanguage('Agregar')}} {{ Util.TranslateLanguage('por') }} {{Util.Price(currentItem.total * currentItem.quantity)}}</div>
                        <div v-if="modalCombo" @click="!currentItem.error ? modalItem=null : ItemError(modalItem)" :class="`btn ${hideModalTitle ? 'invert' : ''} btn-hover flex-fill it-add-to-cart ${currentItem.error ? 'disabled' : ''}`" style="height: 100%" :title="currentItem.error ? Util.TranslateLanguage('Rellena los campos requeridos') : Util.TranslateLanguage('Agregar a mi pedido')">{{Util.TranslateLanguage('Aceptar')}}<span v-show="currentItem.total - modalItem.price > 0"> {{ Util.TranslateLanguage('por') }} {{Util.Price(currentItem.total - modalItem.price)}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        
    </modal>

    <modal v-model="modalCombo" :title="modalCombo && !hideModalTitle ? Util.TranslateLanguage(modalCombo.title) : null" class="modal-combo" :closeable="!hideModalTitle">
        <div :class="`flex-fill flex ${mobile ? 'column' : 'ofy'}`" v-if="modalCombo">
            <div v-show="!mobile && itemImageSize == 'DEFAULT'" class="it-item-image it-item-image-default" :style="{'width': '40%', 'min-width': '40%', height: mobile ? '200px' : '100%', 'background-image': `url('${Util.img(modalCombo.image, 'products')}')`, 'background-size': 'cover', 'background-position': 'center'}"></div>
            
            <div class="flex column">
                <div class="ofy flex-fill flex column relative it-item-container" style="padding: 20px; overflow-x: hidden">

                    <img v-show="mobile && itemImageSize == 'DEFAULT'" :src="Util.img(modalCombo.image, 'products')" class="it-item-image it-item-image-default" style="width: calc(100% + 40px); margin: -20px 0px 20px -20px">

                    <div v-if="Util.TranslateLanguage(modalCombo.description, null, true, modalCombo.id) || itemImageSize == 'SMALL'" class="it-item-info">
                        <div class="flex">
                            <img v-if="itemImageSize == 'SMALL'" :src="Util.img(modalCombo.image, 'products')" class="logo it-item-image it-item-image-small" style="margin-right: 10px; width: 50px; height: 50px">
                            <div class="flex column">
                                <div v-if="hideModalTitle" class="c1 s1 it-item-title">{{ Util.TranslateLanguage(modalCombo.title) }}</div>
                                <div v-if="Util.TranslateLanguage(modalCombo.description, null, true, modalCombo.id)" class="c1 s3 it-item-description">{{Util.TranslateLanguage(modalCombo.description, null, true, modalCombo.id)}}</div>
                            </div>
                        </div>
                        <div class="separator"></div>
                    </div>

                    <div :class="`flex column animated btn btn-hover it-combo-subitem ${currentCombo.subitems[itemIndex].error ? 'selection-required' : ''}`" style="margin-bottom: 10px; flex-shrink: 0; padding: 10px"
                    @click="OpenModalItem(subitem, false)"
                    v-for="(subitem, itemIndex) in modalCombo.products" :key="`subitem-${itemIndex}`" ref="subitems">
                        <div class="flex">
                            <img v-if="subitem.image" :src="Util.img(subitem.image, 'products')" class="logo it-item-image it-item-image-small it-subitem-image-small" style="margin-right: 10px; width: 50px; height: 50px">
                            <div class="flex column flex-fill">
                                <div v-show="currentCombo.subitems[itemIndex].error" class="s2 cn it-combo-required" style="text-align: left; margin-bottom: 2px">{{Util.TranslateLanguage('Selección Requerida')}}</div>
                                <div class="flex flex-fill">
                                    <div class="flex-fill s1 c1 it-combo-info" style="margin-bottom: 5px; text-align: left">
                                        <div v-if="subitem.info" class="s2 c2 it-combo-subitem">{{subitem.info}}</div>
                                        <div class="it-combo-title">{{Util.TranslateLanguage(subitem.title)}}</div>
                                    </div>
                                    <div class="flex align-bottom">
                                        <i class="icon icon-chevron-down c2" style="margin-right: 0px"></i>
                                    </div>
                                </div>
                                <div :class="`ta-left c2 s3 ${modifier.type} it-combo-modifier`" v-for="(modifier, index) in Util.ModifiersList(currentCombo.subitems[itemIndex], !!hideModalTitle)" :key="`modifier-${subitem.id}-${index}`">{{modifier.value}}</div>
                            </div>
                        </div>
                    </div>


                    <div v-show="canAddItems">
                        <div class="btn input label style flex-fill it-combo-comment" title="Editar cantidad">
                            <div :class="`s1 label ${modalCombo.comment_required ? (modalCombo.comment ? 'cy' : 'cn') : ''}`" style="padding-left: 15px; text-align: left">{{modalCombo.comment_instructions ? modalCombo.comment_instructions : Util.TranslateLanguage('Comentario adicional')}}</div>
                            <input v-model="modalCombo.comment" type="text" class="fill s1 w-label" :placeholder="modalCombo.comment_required ? Util.TranslateLanguage('(Requerido)') : Util.TranslateLanguage('(Opcional)')" style="padding: 40px 15px 15px 15px;">
                        </div>
                    </div>

                    <div class="flex-fill"></div>
                </div>
                <div class="flex column unshrink it-combo-add-container" v-show="canAddItems" v-if="!modalCombo.max_quantity_enabled || modalCombo.max_quantity !== 1" style="padding: 0px 20px 20px 20px">
                    <div class="separator"></div>
                    <div :class="`flex ${hideModalTitle ? '' : 'column'}`" style="gap: 10px">
                        <div class="btn btn-hover flex justify-center align-center flex-fill" v-if="hideModalTitle" @click="modalCombo = null">{{Util.TranslateLanguage('Volver')}}</div>
                        <div class="btn btn-hover flex justify-center align-center flex-fill" v-if="hideModalTitle && modalCombo.edit_key" @click="$emit('OnRemove', {key: modalCombo.edit_key}); modalCombo = null; ">{{Util.TranslateLanguage('Quitar')}}</div>
                        <div v-if="!modalCombo.float_quantity" class="flex" style="flex-shrink: 0">
                            <div @click="modalCombo.quantity > 1 ? modalCombo.quantity-- : null" :class="`btn flex align-center btn-hover it-item-subtract ${modalCombo.quantity <= 1 ? 'disabled' : ''}`" style="margin-right: 10px" :title="Util.TranslateLanguage('Quitar')+' 1'"><i class="icon icon-minus"></i></div>
                            <div class="btn input label style flex-fill" :title="Util.TranslateLanguage('Editar cantidad')" :style="hideModalTitle ? {width: '120px'} : ''">
                                <div class="s3 label label-quantity">{{Util.TranslateLanguage('Cantidad')}}</div>
                                <input v-model="modalCombo.quantity" type="text" class="fill s1 ta-center w-label input-quantity" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                            </div>
                            <div @click="modalCombo.quantity++" class="btn flex align-center btn-hover it-item-add" style="margin-left: 10px" :title="Util.TranslateLanguage('Agregar')+' 1'"><i class="icon icon-plus"></i></div>
                        </div>
                        <div v-else class="flex" style="flex-shrink: 0">
                            <div class="btn input style flex-fill" :title="Util.TranslateLanguage('Editar cantidad')" :style="hideModalTitle ? {width: '120px'} : ''">
                                <div class="s3 label label-quantity">{{Util.TranslateLanguage('Cantidad')}}<span v-if="modalCombo.float_quantity_measure"> ({{modalCombo.float_quantity_measure}})</span></div>
                                <input v-model="modalCombo.quantity" type="text" class="fill s1 ta-center w-label input-quantity" oninput="this.value=this.value.replace(/[^0-9,]/g,'');">
                            </div>
                        </div>
                        <div @click="currentCombo.error ? ItemError(modalCombo) : (modalCombo.anticipation ? confirmAnticipation = {modalCombo, currentCombo, key: modalCombo.edit_key} : AddItem(currentCombo, modalCombo.edit_key))" :class="`btn btn-hover ${hideModalTitle ? 'invert' : ''}  flex justify-center align-center it-add-to-cart ${currentCombo.error ? 'disabled' : ''}`" :title="currentCombo.error ? Util.TranslateLanguage('Rellena los campos requeridos') : Util.TranslateLanguage('Agregar a mi pedido')">{{modalCombo.edit_key ? Util.TranslateLanguage('Editar') : Util.TranslateLanguage('Agregar')}} {{Util.TranslateLanguage('por')}} {{Util.Price(currentCombo.total * currentCombo.quantity)}}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </modal>

    <modal v-model="modalModifiers" :title="modalModifiers && !hideModalTitle ? Util.TranslateLanguage(modalModifiers.group.title) : null" desktopHeight="500px" desktopWidth="400px" class="modal-modifiers" :closeable="!hideModalTitle">
        
        <div v-if="modalModifiers" class="fill flex column modal-modifiers-container" style="padding: 20px; overflow-x: hidden">

            <div class="s1 c1" v-if="hideModalTitle">{{ Util.TranslateLanguage(modalModifiers.group.title) }}</div>

            <modifiers-panel :group="modalModifiers.group" :showExpanded="true" :hideModalTitle="hideModalTitle" ref="modsPanelExpand"></modifiers-panel>

            <div class="flex-fill"></div>
            
        </div>

        <div class="flex column" style="padding: 0px 20px 20px 20px">
            <div @click="modalModifiers=null" v-show="canAddItems" :class="`btn btn-hover ${hideModalTitle ? 'invert' : ''}`" style="margin-top: 20px">{{Util.TranslateLanguage('Aceptar')}}</div>
        </div>
        
    </modal>

    <modal v-model="confirmAnticipation" :title="Util.TranslateLanguage('Confirmar producto')" :sizeAuto="true" :closeable="!hideModalTitle">
        <div v-if="confirmAnticipation">
            <div style="padding: 20px" v-if="confirmAnticipation.modalItem">
                <div>{{Util.TranslateLanguage(confirmAnticipation.modalItem.title)}} {{Util.TranslateLanguage('requiere')}} {{ confirmAnticipation.modalItem.anticipation_time }} {{Util.TranslateLanguage('horas de reserva anticipada')}}</div>
                <div class="flex transaction-actions unshrink" style="margin-top: 20px">
                    <div :class="`btn btn-hover flex-fill`" @click="confirmAnticipation=null" :title="Util.TranslateLanguage('Cancelar')"> {{Util.TranslateLanguage('Cancelar')}}</div>
                    <div :class="`btn btn-hover flex-fill`" @click="AddItem(confirmAnticipation.currentItem, confirmAnticipation.key); confirmAnticipation = null" :title="Util.TranslateLanguage('Confirmar')">{{Util.TranslateLanguage('Confirmar')}}</div>
                </div>
            </div>
            <div style="padding: 20px" v-if="confirmAnticipation.modalCombo">
                <div>{{Util.TranslateLanguage(confirmAnticipation.modalCombo.title)}} {{Util.TranslateLanguage('requiere')}} {{ confirmAnticipation.modalCombo.anticipation_time }} {{Util.TranslateLanguage('horas de reserva anticipada')}}</div>
                <div class="flex transaction-actions unshrink" style="margin-top: 20px">
                    <div :class="`btn btn-hover flex-fill`" @click="confirmAnticipation=null" :title="Util.TranslateLanguage('Cancelar')">{{Util.TranslateLanguage('Cancelar')}}</div>
                    <div :class="`btn btn-hover flex-fill`" @click="AddItem(confirmAnticipation.currentCombo, confirmAnticipation.key); confirmAnticipation = null" :title="Util.TranslateLanguage('Confirmar')">{{Util.TranslateLanguage('Confirmar')}}</div>
                </div>
            </div>
        </div>
    </modal>


</div>
</template>

<script>
import VueClamp from 'vue-clamp';
import ModifiersPanel from './ModifiersPanel.vue';

export default {
    components: {VueClamp, ModifiersPanel},
    props: {
        menu: null,
        analytics: null,
        search: '',
        canOpenItems: true,
        canAddItems: true,
        itemPriceDisplay: '',
        itemImageSize: '',
        behaviour: {type: String, default: 'ALL'}, //ALL, TABS, EXPAND
        overwriteOnOpen: {type: Function, default: null},
        hideModalTitle: false,
        superSections : {type : Array, default: []},
        selectedSuperSection: {type: String, default: ''},
    },
    data() {
        return {
            modalItem: null,
            modalCombo: null,
            modalModifiers: null,
            currentSection: '',
            maxLines: {
                titleMobile: 1,
                titleDesktop: 2,
                descriptionMobile: 1,
                descriptionDesktop: 2
            },
            confirmAnticipation: null,
        }
    },
    mounted() {
        if(!this.currentSection && this.behaviour == 'TABS' && this.menu.length)
        {
            for(let i = 0; i < this.menu.length; i++)
            {
                if(this.menu[i].items.length)
                {
                    this.currentSection = this.menu[i].title;   
                    break;
                }
            }
        }
        
        const rootStyle = getComputedStyle(document.documentElement);
        const titleMaxLinesDesktop = parseInt(rootStyle.getPropertyValue('--title-max-lines-desktop').trim());
        if(Number.isInteger(titleMaxLinesDesktop)) this.$set(this.maxLines, 'titleDesktop', titleMaxLinesDesktop);
        const titleMaxLinesMobile = parseInt(rootStyle.getPropertyValue('--title-max-lines-mobile').trim());
        if(Number.isInteger(titleMaxLinesMobile)) this.$set(this.maxLines, 'titleMobile', titleMaxLinesMobile);
        const descriptionMaxLinesDesktop = parseInt(rootStyle.getPropertyValue('--description-max-lines-desktop').trim());
        if(Number.isInteger(descriptionMaxLinesDesktop)) this.$set(this.maxLines, 'descriptionDesktop', descriptionMaxLinesDesktop);
        const descriptionMaxLinesMobile = parseInt(rootStyle.getPropertyValue('--description-max-lines-mobile').trim());
        if(Number.isInteger(descriptionMaxLinesMobile)) this.$set(this.maxLines, 'descriptionMobile', descriptionMaxLinesMobile);
        
    },
    methods: {
        GoToSection(section)
        {
            this.$emit('ClearSearch');
            this.currentSection = section.title;
            const escapedTarget = 'section'+section.title.replace(/ /g, '').replace(/[-\/\\^$*+?.()|[\]{}%]/g, '\\$&');

            setTimeout(() => {
                ScrollTo(document.querySelector(`#${CSS.escape(escapedTarget)}`), {
                    elementToScroll: document.getElementById('menu-container'),
                    verticalOffset: -80,
                    maxDuration: 300
                });
            }, 10);

            if(section && section.items && section.items.length)
            {
                if(this.analytics) this.analytics.Trigger('view_item_list', section);
            }
            
        },
        //Sí link es falso, es porque es un ítem DESDE un combo
        OpenModalItem(item, link = true) {
            
            var it = this.modalCombo ? item : this.Util.Copy(item);
            it.from = !link; //Sí from es true, es porque viene desde (from) un combo
            it.quantity = 1;

            /*
            if(this.ecommerceType == 'VIRTUAL_MENU' && it.modifiers)
            {
                it.modifiers.forEach(group => {
                    group.expand = false;
                });
            }
            */
            if(link){
                const queryParams = window.location.search;
                const newUrl = `${window.location.origin}/${encodeURIComponent(item.title)}%20${queryParams}`;
                setTimeout(() => {
                    //check si la url es igual (cuando se abre un link con el item)
                    //TODO: de todas formas no funciona el boton back del navegador... (limitaciones del navegador??)
                    if(newUrl == window.location.href)
                    {
                        history.replaceState(null, '', `${window.location.origin}${queryParams}`);
                        history.pushState(history.state, item.title, newUrl);
                    }
                    else
                    {
                        //se reemplaza el state del evento modal open
                        history.replaceState(history.state, item.title, newUrl);
                    }
                }, 100);
                
            }

            if(this.overwriteOnOpen && this.overwriteOnOpen(item))
            {
                return this.overwriteOnOpen(item)(item);
            }

            const modifyItem = (modal, item) => {
                if(modal.products && modal.products.length) {
                    return modal.products.forEach((product, ind) => {
                        modifyItem(modal.products[ind], this.Util.Copy(product));
                    });
                }

                if (item.modifiers && item.modifiers.length) {
                    item.modifiers.forEach((group, indexGroup) => {
                        group.modifiers.forEach((modifier, indexModifier) => {
                            if (modifier.auto_select && !group.replacement && !modifier.unavailable) {
                                if (group.multi_select) {
                                    if (modifier.auto_select > 0) {
                                        this.$set(modal.modifiers[indexGroup].modifiers[indexModifier], 'count', modifier.auto_select);
                                    }
                                } else {
                                    this.$set(modal.modifiers[indexGroup].modifiers[indexModifier], 'selected', true);
                                }
                            }
                        });
                    });
                }
            }

            if (item.type == 0) {
                this.modalItem = it;
                modifyItem(this.modalItem, Util.Copy(it));
            } else if (item.type == 1) {
                this.modalCombo = it;
                modifyItem(this.modalCombo, Util.Copy(it));
            }

            if(this.analytics) this.analytics.Trigger('view_item', it);
            this.$emit('OnClickItem', item);
        },
        AddItem(item, key)
        {
            this.$emit('OnAdd', {item: item, key: key});
            //this.modalItem = null;
            //this.modalCombo = null;
        },
        ItemOrder(item)
        {
            var key = item.id.toString();
            var modifiers = [];
            var total = item.price;
            var error = false;
            var index = 0;
            item.modifiers.forEach(group => {
                if(this.Util.GroupRequirements(group).error)
                {   
                    error = {
                        group: group,
                        index: index
                    }
                } 
                group.modifiers.forEach(modifier => {
                    if(!group.multi_select)
                    {
                        if(modifier.selected)
                        {
                            if(modifier.replacement)
                            {
                                let data = {
                                    title: `${modifier.title} > ${modifier.replacement.title}`, 
                                    group: group.title, 
                                    price: modifier.price + modifier.replacement.price,
                                    modifier_original: modifier.title,
                                    modifier_original: modifier.title,
									modifier_replacement: modifier.replacement.title,
									modifier_original_price: modifier.price,
									modifier_replacement_price: modifier.replacement.price,
                                    inventory_references: modifier.replacement.inventory_references,
                                    item: item.title,
                                    id_item: item.id
                                }
                                if(modifier.price_old || modifier.price_overwrite) data.overwrite = true
                                modifiers.push(data);
                                total += (modifier.price + modifier.replacement.price);
                                key += `-${group.title}:${modifier.title}>${modifier.replacement.title}`;
                            }
                            else
                            {
                                let data = {title: modifier.title, group: group.title, price: modifier.price, item: item.title, id_item: item.id, inventory_references: modifier.inventory_references}
                                if(modifier.price_old || modifier.price_overwrite) data.overwrite = true
                                if(modifier.translate_title) data.translate_title = modifier.translate_title
                                modifiers.push(data);
                                total += modifier.price;
                                key += `-${group.title}:${modifier.title}`;
                            }
                        }
                    }
                    else
                    {                        
                        if(modifier.count && modifier.count > 0)
                        {
                            for(var i = 0; i < modifier.count; i++)
                            {
                                let data = {title: modifier.title, group: group.title, price: modifier.price, item: item.title, id_item: item.id, inventory_references: modifier.inventory_references}
                                if(modifier.price_old || modifier.price_overwrite) data.overwrite = true
                                modifiers.push(data);
                                total += modifier.price;
                                key += `-${group.title}:${modifier.title}`;
                            }
                        }
                    }
                });
                index++;
            });
            if(item.comment) key += '-'+item.comment;

            var quantity = item.float_quantity ? parseFloat(item.quantity.toString().replace(/,/g, '.')) : parseInt(item.quantity);
            quantity = parseInt(quantity * 1000) / 1000;
            if(isNaN(quantity) || quantity <= 0)
            {
                error = true;
                quantity = 0;
            }

            if(item.comment_required && !item.comment)
                error = true;

            let data = {
                key: key,
                error: error,
                total: total,
                quantity: quantity,
                item: {
                    id: item.id,
                    title: item.title,
                    image: item.image,
                    price: item.price,
                    section: item.section
                },
                modifiers: modifiers,
                float_quantity: item.float_quantity,
                comment: item.comment ? item.comment : null,
                combo: item.type == 1,
                section: item.section
            }
            
            if(item.translate_title) data.item.translate_title = item.translate_title;

            if(item.price_old || item.price_overwrite) data.item.overwrite = true

            return data
        },
        CloseAll()
        {
            this.modalItem = null;
            this.modalCombo = null;
            this.modalModifiers = null;
            this.confirmAnticipation = null;
            if(this.$refs.modsPanel) this.$refs.modsPanel.modalReplacement = null;
            if(this.$refs.modsPanelExpand) this.$refs.modsPanelExpand.modalReplacement = null;
        },
        ClickSuperSection(section){
            let queryParams = window.location.search;
            // Asegurar que la URL se limpia antes de actualizar
            if(section.title == 'Ver Todos')
            {
                window.history.back();    
            }
            else
            {
                let newUrl = `${window.location.origin}/${encodeURIComponent(section.title)}${queryParams}`;
                history.pushState({ page: "sección", title: section.title }, section.title, newUrl);
            }
            this.$emit('OnClickSuperSection', section.title)
        },
    },
    computed: {
        menuResults() 
        {
            if(!this.menu) return [];
            var search = this.search.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            let tmpMenu = this.Util.Copy(this.menu);

            if(!search) return tmpMenu;

            if(this.analytics) this.analytics.Trigger('search', search);

            var ret = [];
            tmpMenu.forEach(section => {
                var sectionItems = {items: [], title: section.title};
                section.items.forEach(item => {
                    var title = item.translate_title ? item.translate_title.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : item.title.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    if(title.indexOf(search) > -1)
                        sectionItems.items.push(item);
                });
                if(sectionItems.items.length && !section.hideOnSearch)
                    ret.push(sectionItems);
            });
            return ret;
        },
        
        currentItem() {
            if(!this.modalItem) return null;
            return this.ItemOrder(this.modalItem);
        },
        currentCombo() {
            if(!this.modalCombo) return null;

            var key = this.modalCombo.id;
            var error = false;
            var total = this.modalCombo.price;
            var quantity = this.modalCombo.quantity;
            var subitems = [];
            var itemAsModifiers = [];

            var subitemIndex = 0;
            this.modalCombo.products.forEach(subitem => {

                subitem.quantity = 1;
                var subitemDetails = this.ItemOrder(subitem);
                subitemDetails.total -= subitem.price;
                subitems.push(subitemDetails);
                if(subitemDetails.error) error = true;
                key += `-${subitemDetails.key}`;

                var itemAsModifier = {
					title: subitem.title,
					group: subitem.title,
					price: subitemDetails.total,
					item: subitem.title,
					subitem: subitemIndex++,
					modifiers: subitemDetails.modifiers,
					id_item: subitem.id,
					comment: subitem.comment
				};
                
                if(subitem.translate_title) itemAsModifier.translate_title = subitem.translate_title;
                if(subitem.translate_description) itemAsModifier.translate_description = subitem.translate_description;
                itemAsModifiers.push(itemAsModifier);

                total += subitemDetails.total;

            });

            if(this.modalCombo.comment) key += '-'+this.modalCombo.comment;

            let data = {
                key: key,
                error: error,
                total: total,
                quantity: quantity,
                item: {
                    id: this.modalCombo.id,
                    title: this.modalCombo.title,
                    image: this.modalCombo.image,
                    price: this.modalCombo.price,
                },
                modifiers: itemAsModifiers,
                float_quantity: this.modalCombo.float_quantity,
                comment: this.modalCombo.comment ? this.modalCombo.comment : null,
                combo: true,
                subitems: subitems
            }

            if(this.modalCombo.translate_title) data.item.translate_title = this.modalCombo.translate_title;

            return data
        },
    },
    watch: {
        menu(n, o) {
            if(!n) return;
            if(this.behaviour == 'TABS')
            {
                let sections = [];
                for(let i = 0; i < n.length; i++) 
                {
                    if(n[i].items.length) sections.push(n[i].title);
                }
                if(sections.indexOf(this.currentSection) <= -1)
                {
                    if(sections.length) this.currentSection = sections[0];
                }
            }
        }
    }
}
</script>

<style>
.c-section-card {
    background-color: var(--center-bg);
}
</style>